<template>
  <v-app app>
    <v-app-bar app bar color="#333c8b" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"> </v-app-bar-nav-icon>

      <v-toolbar-title v-if="!$vuetify.breakpoint.smAndDown">
        <v-btn to="/" text class="ma-0"> DOMOV</v-btn>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn v-if="isAuthenticated && !$vuetify.breakpoint.smAndDown" to="/member" text>
        <span>{{ firstName }} {{ lastName }}, členstvo v NH: <span v-if="!getMemberStatus">Žiadne</span><span
            v-else-if="getMemberStatus == 'WAITING'">Čakajúce</span> <span
            v-else-if="getMemberStatus == 'ACTIVE'">Aktívne</span> <span v-else-if="getMemberStatus == 'PAUSED'"
            class="text--red">Pozastavené</span><span v-else-if="getMemberStatus == 'CANCELED'"
            class="text--red">Zrušené</span> <span v-else>Žiadne</span></span>
      </v-btn>

      <v-tooltip bottom v-if="isAuthenticated">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="isAuthenticated" icon to="/profile/0" v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>

        </template>
        <span>Účet</span>
      </v-tooltip>

      <router-link v-if="isAuthenticated && isStaff" :to="{ name: 'admin', params: { tabID: 11 } }">
        <v-btn color="info" small class="mx-1">
          ? {{ this.adminNewQuestions }}
        </v-btn>
      </router-link>

      <v-btn v-if="isAuthenticated && isStaff" color="info" small to="/admin/default">
        A
      </v-btn>

      <v-btn v-else-if="isAuthenticated && moderator" color="info" small to="/moderator">
        M
      </v-btn>

      <v-btn v-if="!isAuthenticated" text small to="/login">
        Prihlásenie
      </v-btn>

      <v-btn v-if="!isAuthenticated" text small to="/register">
        Registrácia
      </v-btn>

      <v-tooltip v-if="isAuthenticated" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="isAuthenticated" icon to="/logout" v-bind="attrs" v-on="on">
            <v-icon>mdi-logout</v-icon>
          </v-btn>

        </template>
        <span>Odhlásenie</span>
      </v-tooltip>

    </v-app-bar>

    <v-navigation-drawer v-model="drawer" bottom temporary app width="25%">
      <v-list nav dense>
        <v-list-group no-action style="margin-right: 16px !important;" prepend-icon="mdi-vote">
          <template v-slot:activator>
            <v-list-item-title class="text-body-1" style="color: #D2122E;"><strong>NAŠE</strong> TÉMY A
              HLASOVANIA</v-list-item-title>
          </template>

          <v-list-item v-for="menuItem in getMenuPoll" v-bind:key="menuItem.id" :to="'/poll_category/' + menuItem.id">
            <v-list-item-icon>
              <v-icon>{{ menuItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menuItem.name }}<v-icon small
                  v-if=menuItem.private>mdi-file-hidden</v-icon></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>

        <v-list-item v-if="isAuthenticated" to="/profile/0">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Môj Profil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/about">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>O NAŠOM HNUTÍ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-for="menuItem in getMenu">
          <v-list-group v-if="menuItem.children.length > 0" v-bind:key="menuItem.id" prepend-icon="mdi-filter-variant"
            no-action style="margin-right: 16px !important;">
            <template v-slot:activator>
              <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
            </template>

            <v-list-item v-for="menuItem1 in menuItem.children" :key="menuItem1.id" @click="getPageAddress(menuItem1)">
              <v-list-item-content>
                <v-list-item-title>{{ menuItem1.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else v-bind:key="menuItem.id" @click="getPageAddress(menuItem)">
            <v-list-item-icon>
              <v-icon>mdi-card-account-phone-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item to="/members">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Zoznam členov <strong>NÁŠHO</strong> HNUTIA</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container :fluid="isFluid($route.path)">
        <v-alert class="text-center" color="warning" dark border="bottom" v-if="isAuthenticated && !isVerifiedSmsEmail">
          <span v-if="gender == 'MAN'">
            Nemáš plne aktivovaný účet, preto niektoré funkcie nie sú dostupné (napríklad hlasovanie).
            Tvoje registrácia bola prijatá. Ak Ti ihneď – v krátkom čase do pár minút nepríde overovací emailový PIN kód
            na
            emailovú adresu, ktorú si uviedol pri registrácií, tak si skontroluj, či si zadal v registrácií správne
            svoju
            emailovú adresu alebo skontroluj, či správa neprišla do spamu. Ak Ti nepríde na tvoj mobilný telefón
            overovací
            sms PIN kód, tak si skontroluj telefónne číslo v registrácií, či si ho zadal správne. Ak máš iný problém,
            napíš
            nám ho emailom alebo zavolaj a pokúsime sa ho spolu vyriešiť.
          </span>
          <span v-else>
            Nemáš plne aktivovaný účet, preto niektoré funkcie nie sú dostupné (napríklad hlasovanie).
            Tvoje registrácia bola prijatá. Ak Ti ihneď – v krátkom čase do pár minút nepríde overovací emailový PIN kód
            na
            emailovú adresu, ktorú si uviedla pri registrácií, tak si skontroluj, či si zadala v registrácií správne
            svoju
            emailovú adresu alebo skontroluj, či správa neprišla do spamu. Ak Ti nepríde na tvoj mobilný telefón
            overovací
            sms PIN kód, tak si skontroluj telefónne číslo v registrácií, či si ho zadala správne. Ak máš iný problém,
            napíš
            nám ho emailom alebo zavolaj a pokúsime sa ho spolu vyriešiť.
          </span>
          <v-spacer />
          <v-btn text to="/register_verify">KLIKNI SEM PRE AKTIVÁCIU</v-btn>
        </v-alert>
        <v-fade-transition mode="out-in">
          <router-view :key="$route.path"></router-view>
        </v-fade-transition>
      </v-container>
    </v-main>

    <v-footer id="home-footer" color="#333c8b" dark>
      <cookie-law buttonText="Súhlasím" buttonDecline buttonDeclineText="Nesúhlasím">
        <div slot="message">
          Táto stránka používa súbory cookies.
          <a href="https://docs.google.com/gview?embedded=true&url=https://api.nasehnutie.sk/uploads/816b1040-6e65-49ef-9084-29c1a56509a4.pdf"
            target="_blank" @click.stop>Pre viac informácií kliknite sem.</a>
        </div>
      </cookie-law>

      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <div class="
                d-flex
                flex-wrap
                justify-md-start justify-center justify-md-none
              ">
              <template v-for="(s, i) in social">
                <a :key="s" class="white--text pa-1 pa-md-0" href="#" v-text="s" />

                <v-responsive v-if="i < social.length - 1" :key="`divider-${s}`" class="mx-4 shrink hidden-sm-and-down"
                  max-height="20">
                  <v-divider vertical />
                </v-responsive>
              </template>
            </div>
          </v-col>

          <v-col class="text-center text-md-right" cols="12" md="6">
            &copy; 2024
          </v-col>
        </v-row>
      </v-container>
    </v-footer>

    <v-snackbar v-model="isError" color="error" timeout="-1">
      <ul style="list-style-type: none">
        <li v-if="typeof getErrorMessage === 'object' && getErrorMessage !== null && 'detail' in getErrorMessage">
          <span> {{ getErrorMessage.detail }} </span>
        </li>

        <template v-else>
          <li v-for="(val, index) in getErrorMessage" :key="index">
            <span v-if="index != 'non_field_errors'"> {{ getLocalizedError(index) }}: </span>

            {{ val[0] }}

          </li>
        </template>
      </ul>

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="dismissError()"> Zavrieť </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import CookieLaw from 'vue-cookie-law'
import { throws } from "assert";

export default {
  name: "App",

  components: { CookieLaw },

  data: () => ({
    user: "",
    drawer: true,

    fields_map: {
      username: "Prihlasovacie meno",
      password1: "Heslo",
      password2: "Heslo",
      password: "Heslo",
      email: "Email",
      first_name: "Meno",
      last_name: "Priezvisko",
      bank_account: "IBAN",
      phone_number: "Telefónne číslo",
      gender: "Pohlavie",
      address_number: "Číslo",
      address_street: "Ulica",
      address_zip_code: "PSČ",
      address_country: "Štát",
      address_town: "Mesto/Obec",
      address_okres: "Okres",
      address_kraj: "Kraj",
    },

    social: ["Facebook", "Twitter", "Instagram", "Linkedin"],
  }),

  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "username",
      "isVerified",
      "isVerifiedBank",
      "isVerifiedSmsEmail",
      "isStaff",
      "moderator",
      "getMemberStatus",
      "firstName",
      "lastName",
      "gender"
    ]),

    ...mapGetters("notifications", ["isError", "getErrorMessage"]),
    ...mapGetters("menu", ["getMenu", "getMenuPoll", "adminNewQuestions"]),
  },

  mounted: function () {
    this.$store.dispatch("menu/fetchMenu");
    this.$store.dispatch("menu/fetchMenuPoll");
    this.$store.dispatch("settings/fetchSettings");
    this.$store.dispatch("auth/initialize");
    this.$store.commit("notifications/dismissError");
  },

  methods: {
    isFluid(path) {
      if (path.includes('/admin'))
        return true;
      return false;
    },
    getLocalizedError(error) {
      if (error in this.fields_map)
        return this.fields_map[error];
      else return error;
    },
    dismissError() {
      this.$store.commit("notifications/dismissError");
    },

    getPageAddress(menuItem) {
      this.$router.push({ name: "page", params: { id: menuItem.id } });
    },
  },
};
</script>

<style lang="sass">
#home-footer a
  text-decoration: none
</style>

<style scoped>
.v-list__group__header__prepend-icon {
  color: red;
}
</style>